<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script lang="ts">
import {storeToRefs} from "pinia";
import {useAppStore} from "@/stores/app";
import {useHead} from "@unhead/vue";
import {useRoute} from "vue-router/composables";
import {defineComponent} from "vue";

export default defineComponent({
  setup() {
    const route = useRoute();
    const {isLC, isDarkMode} = storeToRefs(useAppStore());

    useHead(() => {
      // without this, the function doesn't seem to update when route.meta.title changes
      // probably some weird watchEffect thing that could be specified in a cleaner way, but this works for now
      if (route.meta?.title) {
        /* empty */
      }

      return {
        titleTemplate: (titleChunk) => {
          const chunks = [
            titleChunk,
            route.meta?.title,
            `Olympus ${isLC.value ? "Life Control v3" : "Stats"}`,
          ];
          return chunks.filter((chunk) => !!chunk?.length).join(" - ");
        },
        link: () => [{rel: "icon", href: isLC.value ? "favicon/lc.ico" : "favicon/default.ico"}],
        meta: isDarkMode.value ? [{name: "darkreader-lock"}] : [],
      };
    });
  },
  mounted() {
    let theme;
    if (localStorage.getItem("isDarkMode")) {
      theme = localStorage.getItem("isDarkMode") === "true" ? "dark" : "material";
    } else {
      // Use system preferences if available, otherwise default to 'dark'
      theme =
        window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches
          ? "dark"
          : "material";
    }
    // This store commit is needed due to the page refresh occurring on the dark mode transition
    // and it utilizing the defaulted boolean "false" value -- Need to re-set state on mounted() based off localStorage
    const store = useAppStore();
    store.setDarkMode(theme === "dark");
    document.body.setAttribute("data-theme", theme);

    import(`@/vendor/styles/bootstrap-${theme}.scss`);
    import(`@/vendor/styles/appwork-${theme}.scss`);
    import(`@/vendor/styles/theme-vibrant-${theme}.scss`);
    import(`@/vendor/styles/colors-${theme}.scss`);

    // @ts-ignore
    import("@/vendor/styles/uikit.scss");
    // @ts-ignore
    import("@/vendor/libs/vue-notification/vue-notification.scss");
    // @ts-ignore
    import("@/style.scss");
  },
});
</script>
