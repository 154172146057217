import {RouteConfig} from "vue-router";
import {User} from "@/stores/types";
import {useAppStore} from "@/stores/app";

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: () => import("@/layout/DefaultLayout.vue"),
    children: [
      {
        path: "/",
        name: "index",
        redirect: {name: "top"},
      },
      {
        path: "/stats",
        name: "index-stats",
        redirect: {name: "top"},
      },

      // pure stats views
      {
        path: "/stats/top/",
        name: "top",
        component: () => import("@/views/stats/TopView.vue"),
        meta: {
          title: "Top 50",
        },
      },
      {
        path: "/stats/faction/:faction/",
        name: "top-faction",
        component: () => import("@/views/stats/TopFactionView.vue"),
      },
      {
        path: "/stats/faction/:faction/coc/",
        name: "coc",
        component: () => import("@/views/FactionCocView.vue"),
      },
      {
        path: "/stats/wanted/",
        name: "most-wanted",
        component: () => import("@/views/stats/MostWantedView.vue"),
        meta: {
          title: "Most Wanted",
        },
      },
      {
        path: "/stats/territories/",
        name: "territories",
        component: () => import("@/views/stats/TerritoriesView.vue"),
        meta: {
          title: "Territories",
        },
      },
      {
        path: "/stats/conquest/",
        name: "conquest",
        component: () => import("@/views/stats/ConquestView.vue"),
        meta: {
          title: "Conquest Stats",
        },
      },
      {
        path: "/stats/conquest/schedule/",
        name: "conquest-schedule",
        component: () => import("@/views/stats/ConquestScheduleView.vue"),
        meta: {
          title: "Conquest Schedule",
        },
      },
      {
        path: "/stats/conquest/ratings/",
        name: "conquest-ratings",
        component: () => import("@/views/stats/ConquestRatingsView.vue"),
        meta: {
          title: "Conquest Map Ratings",
        },
      },
      {
        path: "/stats/auction/:item?/",
        name: "auction",
        component: () => import("@/views/stats/AuctionHouseView.vue"),
        meta: {
          title: "Auction House",
        },
      },
      {
        path: "/stats/goals/",
        name: "goals",
        component: () => import("@/views/stats/GoalsView.vue"),
        meta: {
          title: "Community Goals",
        },
      },
      {
        path: "/stats/map/",
        name: "map",
        component: () => import("@/views/stats/MapView.vue"),
        meta: {
          title: "Olympus Map",
          layoutFullscreen: true,
        },
      },

      // detail views
      {
        path: "/:mode/houses/:id/",
        name: "house",
        component: () => import("@/views/HouseDetailView.vue"),
        meta: {
          title: "House",
        },
      },
      {
        path: "/:mode/vehicles/:id/",
        name: "vehicle",
        component: () => import("@/views/VehicleDetailView.vue"),
        meta: {
          title: "Vehicles",
        },
      },
      {
        path: "/:mode/gangvehicles/:id/",
        name: "gangvehicle",
        component: () => import("@/views/GangVehicleDetailView.vue"),
        meta: {
          title: "Gang Vehicle",
        },
      },
      {
        path: "/:mode/players/:id/",
        name: "player",
        component: () => import("@/views/PlayerDetailView.vue"),
        meta: {
          title: "Player",
        },
      },
      {
        path: "/me/",
        name: "player-self",
        redirect: () => {
          return {name: "player", params: {mode: "stats", id: useAppStore().user!.steamId}};
        },
      },
      {
        path: "/services",
        name: "services",
        component: () => import("@/views/ServicesView.vue"),
        meta: {
          title: "Services",
        },
      },
      {
        path: "/:mode/gangs/:id/",
        name: "gang",
        component: () => import("@/views/GangDetailView.vue"),
        meta: {
          title: "Gang",
        },
      },
      {
        path: "/:mode/gangsheds/:id/",
        name: "gangshed",
        component: () => import("@/views/GangShedDetailView.vue"),
        meta: {
          title: "Shed",
        },
      },
      {
        path: "/:mode/disputes/:id/",
        name: "dispute-detail",
        component: () => import("@/views/DisputeDetailView.vue"),
        meta: {
          title: "Dispute",
        },
      },
      {
        path: "/evidence-locker/",
        name: "evidence-locker",
        component: () => import("@/views/stats/EvidenceLockerView.vue"),
        meta: {
          title: "Evidence Locker",
        },
      },
      {
        path: "/staff-shop/",
        name: "staff-shop",
        component: () => import("@/views/stats/StaffShopView.vue"),
        meta: {
          hasRoles: (user: User) => user.roles.STAFF_SHOP,
          title: "Staff Shop",
        },
      },

      // pure lc views
      {
        path: "/lc/users/",
        name: "user-search",
        component: () => import("@/views/admin/UserSearchView.vue"),
        meta: {
          hasRoles: (user: User) => user.roles.SEARCH_USER,
          title: "User Search",
        },
      },
      {
        path: "/lc/users/:id/",
        name: "user-detail",
        component: () => import("@/views/admin/UserDetailView.vue"),
        meta: {
          hasRoles: (user: User) => user.roles.USER_VIEW,
          title: "User",
        },
      },
      {
        path: "/lc/groups/",
        name: "group-list",
        component: () => import("@/views/admin/GroupListView.vue"),
        meta: {
          hasRoles: (user: User) => user.isSuperuser,
          title: "Groups",
        },
      },
      {
        path: "/lc/groups/:id/",
        name: "group-detail",
        component: () => import("@/views/admin/GroupsDetailView.vue"),
        meta: {
          hasRoles: (user: User) => user.isSuperuser,
          title: "Group",
        },
      },
      {
        path: "/lc/search/",
        name: "search",
        component: () => import("@/views/lc/SearchView.vue"),
        meta: {
          hasRoles: (user: User) => user.roles.SEARCH,
          title: "Search",
        },
      },
      {
        path: "/lc/overview/",
        name: "overview",
        component: () => import("@/views/lc/WhitelistOverviewView.vue"),
        meta: {
          hasRoles: (user: User) => user.roles.WHITELIST_OVERVIEW,
          title: "Overview",
        },
      },
      {
        path: "/lc/disputes/",
        name: "disputes",
        component: () => import("@/views/lc/DisputesView.vue"),
        meta: {
          hasRoles: (user: User) => user.roles.DISPUTE_VIEW,
          title: "Disputes",
        },
      },
      {
        path: "/lc/staff-performance/",
        name: "performance",
        component: () => import("@/views/lc/StaffPerformanceView.vue"),
        meta: {
          hasRoles: (user: User) => user.roles.PLAYER_VIEW_TICKETS_OTHER,
          title: "Staff Perfomance",
        },
      },
      {
        path: "/lc/rock-kicker-9000/",
        name: "wiki-ip-check",
        component: () => import("@/views/lc/RockKickerView.vue"),
        meta: {
          hasRoles: (user: User) => user.roles.VIEW_PAGE_WIKI_IP_CHECK,
          title: "Rock-Kicker-9000",
        },
      },
      {
        path: "/lc/goals/",
        name: "lc-goals",
        component: () => import("@/views/lc/GoalsLCView.vue"),
        meta: {
          hasRoles: (user: User) => user.roles.COMMUNITY_GOALS_ADMIN,
          title: "LC Community Goals",
        },
      },
      {
        path: "/lc/missions/",
        name: "missions",
        component: () => import("@/views/lc/MissionFileView.vue"),
        meta: {
          hasRoles: (user: User) => user.roles.MISSION_FILE_ADMIN,
          title: "Missions",
        },
      },
      {
        path: "/lc/notes/",
        name: "notes",
        component: () => import("@/views/lc/NotesListView.vue"),
        meta: {
          hasRoles: (user: User) =>
            user.roles.LC_NOTE_VIEW ||
            user.roles.LC_NOTE_APD ||
            user.roles.LC_NOTE_RNR ||
            user.roles.LC_NOTE_SWAT ||
            user.roles.LC_NOTE_SUPPORT_TEAM ||
            user.roles.LC_NOTE_CIV_COUNCIL,
          title: "Notes",
        },
      },
      {
        path: "/lc/notes/:id/",
        name: "note-detail",
        component: () => import("@/views/lc/NoteDetailView.vue"),
        meta: {
          hasRoles: (user: User) =>
            user.roles.LC_NOTE_VIEW ||
            user.roles.LC_NOTE_APD ||
            user.roles.LC_NOTE_RNR ||
            user.roles.LC_NOTE_SWAT ||
            user.roles.LC_NOTE_SUPPORT_TEAM ||
            user.roles.LC_NOTE_CIV_COUNCIL,
          title: "Notes",
        },
      },
      {
        path: "/lc/logs-download",
        name: "logs-download",
        component: () => import("@/views/lc/LogsView.vue"),
        meta: {
          hasRoles: (user: User) => user.roles.LIFE_LOGS,
          title: "Logs",
        },
      },
      {
        path: "/lc/logs-query",
        name: "logs-query",
        component: () => import("@/views/lc/LogsQueryView.vue"),
        meta: {
          hasRoles: (user: User) => user.roles.LIFE_LOGS,
          title: "Logs",
        },
      },
      {
        path: "/lc/online-players/",
        name: "online-players",
        component: () => import("@/views/lc/OnlinePlayers.vue"),
        meta: {
          hasRoles: (user: User) => user.roles.LC_ONLINE_PLAYERS_VIEW,
          title: "Online Players",
        },
      },
      {
        path: "/lc/audit-log/",
        name: "audit-log",
        component: () => import("@/views/lc/AuditLogView.vue"),
        meta: {
          hasRoles: (user: User) => user.roles.LC_AUDIT_LOG,
          title: "Audit Log",
        },
      },
    ],
  },
  {
    path: "/auth",
    component: () => import("@/layout/AuthLayout.vue"),
    children: [
      {
        path: "/auth/logout/",
        name: "logout",
        component: () => import("@/views/auth/LogoutView.vue"),
        meta: {
          title: "Logout",
        },
      },
      {
        path: "/auth/login/",
        name: "login",
        component: () => import("@/views/auth/LoginView.vue"),
        meta: {
          title: "Login",
          isPublic: true,
        },
      },
      {
        path: "/auth/login/complete/",
        name: "login-complete",
        component: () => import("@/views/auth/LoginCompleteView.vue"),
        meta: {
          title: "Login Complete",
          isPublic: true,
        },
      },
    ],
  },
  {
    path: "/error",
    component: () => import("@/layout/DefaultLayout.vue"),
    children: [
      {
        path: "/error/forbidden",
        name: "403",
        component: () => import("@/views/error/Forbidden.vue"),
        meta: {
          isPublic: true,
          title: "403 Forbidden",
        },
      },
      {
        path: "/error/server",
        name: "500",
        component: () => import("@/views/error/ServerError.vue"),
        meta: {
          isPublic: true,
          title: "500 Server Error",
        },
      },

      {
        // 404 Not Found page
        path: "*",
        component: () => import("@/views/error/NotFound.vue"),
        meta: {
          title: "404 Not Found",
        },
      },
    ],
  },
];

export default routes;
