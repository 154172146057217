import Vue from "vue";
import Router from "vue-router";
import routes from "./routes";
import globals from "@/globals";
import {authGuard, modeGuard} from "@/router/guards";
import {useAppStore} from "@/stores/app";

Vue.use(Router);

const router = new Router({
  base: "/",
  mode: "hash",
  routes: routes,
});

router.afterEach(() => {
  // On small screens collapse sidenav
  // @ts-ignore
  if (
    window.layoutHelpers &&
    window.layoutHelpers.isSmallScreen() &&
    !window.layoutHelpers.isCollapsed()
  ) {
    const store = useAppStore();
    // @ts-ignore
    store.closeSidenav();
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0);
});

router.beforeEach((to, from, next) => {
  const store = useAppStore();
  if (store.error) store.clearError();
  setTimeout(() => next(), 10);
});

router.beforeEach(authGuard());
router.beforeEach(modeGuard());

export default router;
